/** react core libraries */
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Route,  BrowserRouter as Router,Routes } from 'react-router-dom' 

/** shared components */
import Header from './Components/header_new';
import Footer from './Components/footer_new';
import LoginModal from './Components/modals/loginModal';
import ForgetPasswordModal from './Components/modals/forgetPasswordModal';
import Chatbot from './Components/chatbot';
import SharedComponent from './Components/sharedComponent';
import ScrollToTop from './helpers/scrollToTop';
 
/** contexts components */
import Langauge ,{UserContext} from './context/languageContext';
import Auth from './context/AuthContext';
import Profile from './context/ProfileContext';


/** inside router components */
// import About from './Components/about'
// import LandingPage from './Components/landingpage.js'
// import CourseDetails from './Components/courseDetails';
// import TermsConditions from "./Components/termsConditions";
// import PrivacyPolicy from "./Components/privacyPolicy";
// import PaymentPolicy from "./Components/paymentPolicy";
// import Faqs from './Components/faqs';
// import ContactUs from './Components/contact_us';
// import Register from './Components/register';
// import CourseModules from './Components/courseModules.js'
// import Dashboard from './Components/dashboard.js';
// import AddedChildren from './Components/addedChildren';
// import UserProfile from './Components/userProfile';
// import PageNotFound from './Components/pageNotFound';
// import CourseCertificates from './Components/courseCertificates.js'
// import PricesWizard from './Components/pricesWizard';
// import CheckoutForm from './Components/checkoutForm3';
// import NotificationsManagement from './Components/notificationsManage';
// import Notifications from './Components/notifications';
// import Certificate from './Components/certificate';
// import Newsletter from './Components/newsletter';
// import ResetPassword from './Components/resetPassword';
// import AccountActivation from './Components/accountActivation';
// import ChildAccountActivation from './Components/childAccountActivation';
// import MsisdnRegisterWizard from './Components/msisdnRegisterWizard';
// import MsisdnAutoLogin from './Components/msisdnAutoLogin';
// import JoinOurTeam from './Components/JoinOurTeam';
// import CategoryCourses from './Components/categoryCourses'
// import PaymentInfo from './Components/paymentInfo';
// import MenaRoute from './Components/menaRoute';
// import CampaignJourney from './Components/campaignJourney';
// import CampaignCompleteRegistration from './Components/campaignCompleteRegistration';
// import UserBillingHistory from './Components/userBillingHistory';
// import Devices from './Components/devices';
// import Courses from './Components/courses';
// import CampaignJourneyMobile from './Components/campaignJourneyMobile';
// import ChangePaymentMethod from './Components/changePaymentMethod';
// import CampaignJourney2 from './Components/campaignJourney2';
// import CampaignJourneyMobile2 from './Components/campaignJourneyMobile2';
// import CampaignJourney3 from './Components/campaignJourney3';
// import CampaignJourney4 from './Components/campaignJourney4';
// import CampaignJourney7 from './Components/campaignJourney7';
// import Testimonials from './Components/testimonials';
// import CampaignJourneyThemes from './Components/campaignJourneyThemes';
/** functions and helpers */
import config from './Components/config';
import ApiHelper from './helpers/ApiHelper';
// import CampaignJourney8 from './Components/campaignJourney8';
// import UserRegistration from './Components/userRegistration';
// import CompleteUserRegistration from './Components/completeUserRegistration';
// import Login2 from './Components/login2';
// import Survey from './survey/survey';
// import PaypalSimulation from './Components/paypalSimulation';
// import CampaignJourneyThemesMobile from './Components/campaignJourneyThemesMobile';
// import SurveyModal from './Components/modals/surveyModal';
// import CourseProjectPage from './Components/courseProjectPage';
// import CampaignHeader from './Components/campaign_header';
// import CampaignLP from './Components/campaign_LP';
// import CampaignFooter from './Components/campaign_footer';

// import VideoPage from './Components/videoPage.js';
import TestConversion from './Components/testConversion';
import Blogs from './Components/blogs';
import Blog from './Components/blog';


//import { HelmetProvider } from 'react-helmet-async';
// import ReactGA from 'react-ga';
// const TRACKING_ID = "UA-114526787-1"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);






const LoadingFallback = () => <div></div>;

const About = lazy(() => import('./Components/about'));
const LandingPage = lazy(() => import('./Components/landingPage_new'));
const CourseDetails = lazy(() => import('./Components/courseDetails'));
const TermsConditions = lazy(() => import('./Components/termsConditions'));
const PrivacyPolicy = lazy(() => import('./Components/privacyPolicy'));
const PaymentPolicy = lazy(() => import('./Components/paymentPolicy'));
const Faqs = lazy(() => import('./Components/faqs'));
const ContactUs = lazy(() => import('./Components/contact_us'));
const Register = lazy(() => import('./Components/register'));
const CourseModules = lazy(() => import('./Components/courseModules'));
const Dashboard = lazy(() => import('./Components/dashboard'));
const DashboardCourses = lazy(() => import('./Components/dashboardCourses'));
const AddedChildren = lazy(() => import('./Components/addedChildren'));
const UserProfile = lazy(() => import('./Components/userProfile'));
const PageNotFound = lazy(() => import('./Components/pageNotFound'));
const CourseCertificates = lazy(() => import('./Components/courseCertificates'));
const PricesWizard = lazy(() => import('./Components/pricesWizard'));
const CheckoutForm = lazy(() => import('./Components/checkoutForm3'));
const NotificationsManagement = lazy(() => import('./Components/notificationsManage'));
const Notifications = lazy(() => import('./Components/notifications'));
const Certificate = lazy(() => import('./Components/certificate'));
const Newsletter = lazy(() => import('./Components/newsletter'));
const ResetPassword = lazy(() => import('./Components/resetPassword'));
const AccountActivation = lazy(() => import('./Components/accountActivation'));
const ChildAccountActivation = lazy(() => import('./Components/childAccountActivation'));
const MsisdnRegisterWizard = lazy(() => import('./Components/msisdnRegisterWizard'));
const MsisdnAutoLogin = lazy(() => import('./Components/msisdnAutoLogin'));
const JoinOurTeam = lazy(() => import('./Components/JoinOurTeam'));
const CategoryCourses = lazy(() => import('./Components/categoryCourses'));
const PaymentInfo = lazy(() => import('./Components/paymentInfo'));
const MenaRoute = lazy(() => import('./Components/menaRoute'));
const CampaignJourney = lazy(() => import('./Components/campaignJourney'));
const CampaignCompleteRegistration = lazy(() => import('./Components/campaignCompleteRegistration'));
const UserBillingHistory = lazy(() => import('./Components/userBillingHistory'));

const Devices = lazy(() => import('./Components/devices'));
const Courses = lazy(() => import('./Components/courses'));
const CampaignJourneyMobile = lazy(() => import('./Components/campaignJourneyMobile'));
const ChangePaymentMethod = lazy(() => import('./Components/changePaymentMethod'));
const CampaignJourney2 = lazy(() => import('./Components/campaignJourney2'));

const CampaignJourneyMobile2 = lazy(() => import('./Components/campaignJourneyMobile2'));
const CampaignJourney3 = lazy(() => import('./Components/campaignJourney3'));
const CampaignJourney4 = lazy(() => import('./Components/campaignJourney4'));
const CampaignJourney7 = lazy(() => import('./Components/campaignJourney7'));
const Testimonials = lazy(() => import('./Components/testimonials'));
const CampaignJourneyThemes = lazy(() => import('./Components/campaignJourneyThemes'));
//const config = lazy(() => import('./Components/config'));

const CampaignJourney8 = lazy(() => import('./Components/campaignJourney8'));
const UserRegistration = lazy(() => import('./Components/userRegistration'));
const CompleteUserRegistration = lazy(() => import('./Components/completeUserRegistration'));
const Survey = lazy(() => import('./survey/survey'));
const PaypalSimulation = lazy(() => import('./Components/paypalSimulation'));
const CampaignJourneyThemesMobile = lazy(() => import('./Components/campaignJourneyThemesMobile'));
const SurveyModal = lazy(() => import('./Components/modals/surveyModal'));
const CourseProjectPage = lazy(() => import('./Components/courseProjectPage'));
const CampaignHeader = lazy(() => import('./Components/campaign_header'));
const CampaignLP = lazy(() => import('./Components/campaign_LP'));
const CampaignFooter = lazy(() => import('./Components/campaign_footer'));
const Login2 = lazy(() => import('./Components/login2'));
const LocalStreaming = lazy(() => import('./Components/localStreaming'));


const AppleCallback = lazy(() => import('./Components/appleCallback'));




const App = () =>
{      
  const langKeyword = React.useContext(UserContext);   
  const [sharedCourses, setSharedCourses] = useState([]);
  const searchParams = new URLSearchParams(document.location.search);
  const isLogin = searchParams.get('login');
  let campaignType = searchParams.get('campType');
  if(!campaignType || campaignType == undefined || campaignType==0 || campaignType==1){
    campaignType = 1
  }

  const lang = searchParams.get('lang') || '';

  
  let basename = '';
  let pathlang = lang;

  if(document.location.pathname.startsWith("/ar", 0)){
    basename = '/ar';
    pathlang = 'ar';
    localStorage.setItem('lang','ar');
  }
  else if(document.location.pathname.startsWith("/en", 0)){
    basename = '/en';
    pathlang = 'en';
    localStorage.setItem('lang','en');
  }

  if(localStorage.getItem('lang') == 'ar' && !basename){
    const newBasename = '/ar';
    const nextState = { additionalInformation: 'basename /ar' };
    //window.location.pathname = newBasename  +document.location.pathname;
    window.history.pushState(nextState, document.title, newBasename  +document.location.pathname + document.location.search);
    basename = newBasename;
  }
  else if(!basename){
    const newBasename = '/en';
    const nextState = { additionalInformation: 'basename /en' };
    //window.location.pathname = newBasename  +document.location.pathname;
    window.history.pushState(nextState, document.title, newBasename  +document.location.pathname + document.location.search);
    basename = newBasename;
  }

  localStorage.setItem('basename',basename);

  let isCampaignLP = document.location.pathname.indexOf(basename+'/MawahebCamp') !== -1 || document.location.pathname == basename+'/MawahebCamp';

  let isSurvey = document.location.pathname == basename+'/survey' ? 1 :0;
  isSurvey = isSurvey || document.location.pathname == basename+'/survey/long' ? 1 :0;
  useEffect(()=> {
    ApiHelper.excuteApi(config.coursesApi,'get','{}',setSharedCourses);
  },[]);
   
  document.querySelector('meta[name="description"]').setAttribute("content", 'نمي مهارات أبنائك في موقع مواهب أونلابن, منصتنا سهلة ويمكن الوصول إليها من أي مكان وفي أي وقت وتحتوي على العديد من المواضيع لتزويد الأطفال بتجربة تعليمية إيجابية وملهمة');
  document.querySelector('meta[name="ogTitle"]').setAttribute("content", 'Mawaheb Online');
  document.querySelector('meta[name="ogDescription"]').setAttribute("content", 'نمي مهارات أبنائك في موقع مواهب أونلابن, منصتنا سهلة ويمكن الوصول إليها من أي مكان وفي أي وقت وتحتوي على العديد من المواضيع لتزويد الأطفال بتجربة تعليمية إيجابية وملهمة'); 
  
  document.title="Mawaheb ";


    return (
      <>   
        {isSurvey ? 
        <Router basename={basename}>
          <Auth>
            <Langauge> 
                <ScrollToTop/>
                <Routes>
                <Route exact={true} path="/survey" element={<Survey isLong={false} lang={pathlang} basename={basename}/>}/>
                  <Route  path="/survey/long" element={<Survey isLong={true} lang={pathlang}  basename={basename} />}/>
                </Routes> 
            </Langauge>  
          </Auth>
        </Router>              
              
        :
        <> 

        <Chatbot/>
        <Router basename={basename}> 
        <Suspense fallback={<LoadingFallback />}>
          <Auth>   
            <Langauge>   
              <ScrollToTop/>
              <SharedComponent/>
              {!lang && 

              isCampaignLP ?  
                <>
                <CampaignHeader sharedCourses={sharedCourses}/>
                <Routes>               
                <Route path="/MawahebCamp" element={<CampaignLP sharedCourses={sharedCourses}/>} >
                    <Route path=":status" element={<CampaignLP sharedCourses={sharedCourses}/>} />
                </Route>
                </Routes>               
                <CampaignFooter/>
                </> 
                :  
              <Profile>
                <Header sharedCourses={sharedCourses}/>
                <Routes>               
                  <Route  exact path="/ar" element={<LandingPage  sharedCourses={sharedCourses}/>} />
                  <Route  path="/ar/courseDetails/:id/:name" element={<CourseDetails />} />
                  </Routes>
                <Routes>               
                  <Route  exact path="/" element={<LandingPage  sharedCourses={sharedCourses}/>} />
                  <Route  path="/about" element={<About />} />
                  <Route  path="/prices" element={<PricesWizard/>} />
                  <Route  path="/courseDetails/:id/:name" element={<CourseDetails />} />
                  <Route  path="/course/:id/:name" element={<CourseDetails />} />
                  <Route  path="/TermsConditions" element={<TermsConditions />} />
                  <Route  path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                  <Route  path="/PaymentPolicy" element={<PaymentPolicy />} />
                  <Route  path="/Faqs" element={<Faqs />} />
                  <Route  path="/Blogs" element={<Blogs />} />
                  <Route  path="/Blog/:id" element={<Blog />} />
                  <Route  path="/ContactUs" element={<ContactUs />} />
                  <Route  path="/register" element={<Register />} />
                  <Route  path="/register/:refreshToken" element={<Register />} />
                  <Route  path="/register/:token/:email" element={<Register />} />

                  <Route  path="/courseModules/:id/:courseId/:courseName" element={<CourseModules/>}>
                      <Route  path=":videoLang" element={<CourseModules/>}/>
                  </Route>

                  <Route  path="/courseCertificates/:courseId/:courseName/:userId" element={<CourseCertificates/>} />
                  {/* <Route  path="/dashboard/:courseId" element={<Dashboard />} />
                  <Route  path="/selfDashboard/:courseId" element={<Dashboard parentItself={true}/>} /> */}

                  <Route  path="/dashboard/:courseId" element={<DashboardCourses  sharedCourses={sharedCourses}/>} />
                  <Route  path="/selfDashboard/:courseId" element={<DashboardCourses parentItself={true} sharedCourses={sharedCourses}/>} />
                  <Route  path="/dashboard" element={<DashboardCourses  sharedCourses={sharedCourses}/>} />
                  <Route  path="/selfDashboard" element={<DashboardCourses parentItself={true} sharedCourses={sharedCourses}/>} />                  

                  <Route  path="/added-children" element={<AddedChildren />} />
                  <Route  path="/added-children/:refresh" element={<AddedChildren />} />
                  <Route  path="/profile" element={<UserProfile />} />
                  <Route  path="/notifications-management" element={<NotificationsManagement />} />
                  <Route  path="/notifications" element={<Notifications/>} />
                  <Route  path="*" element={<PageNotFound/>} />
                  {/* <Route  path="/checkout/:productId/:price/" element={<CheckoutForm/>} /> */}
                  <Route  path="/checkout/:planId/:promoCode/" element={<CheckoutForm/>} />
                  <Route  path="/checkout/:planId" element={<CheckoutForm/>} />
                  <Route  path="/certificate/:courseId/:moduleId/:userId/:download" element={<Certificate/>} />
                  <Route  path="/newsletter" element={<Newsletter/>} />
                  <Route  path="/resetPassword/:token/:email" element={<ResetPassword/>} />
                  <Route  path="/accountActivation/:token/:id" element={<AccountActivation/>} />
                  <Route  path="/accountActivation/:token/:id/:platform" element={<AccountActivation/>} />
                  <Route  path="/childAccountActivation/:token/:id" element={<ChildAccountActivation/>} />
                  <Route  path="/login" element={<LandingPage  sharedCourses={sharedCourses}/>} />
                  <Route  path="/authMobile" element={<MsisdnRegisterWizard/>} />
                  <Route  path="/resetMobile" element={<MsisdnRegisterWizard reset={true}/>} />
                  
                  <Route  path="/login2/:rtk/:id" element={<Login2/>} />
                  <Route  path="/autoLogin" element={<MsisdnAutoLogin/>} />
                  <Route  path="/join-our-team" element={<JoinOurTeam/>} />
                  <Route  path="/categoryCourses/:catId/:catName" element={<CategoryCourses/>} />
                  <Route  path="/paymentInfo" element={<PaymentInfo/>} />
                  <Route  path="/userBillingHistory" element={<UserBillingHistory/>} />
                  { campaignType ==1 && <Route  path="/campaign" element={<CampaignJourney/>} />}
                  { campaignType ==2 && <Route  path="/campaign" element={<CampaignJourney2/>} />}
                  { campaignType ==3 && <Route  path="/campaign" element={<CampaignJourneyMobile/>} />}
                  { campaignType ==4 && <Route  path="/campaign" element={<CampaignJourneyMobile2/>} />}
                  { campaignType ==5 && <Route  path="/campaign" element={<CampaignJourney3/>} />}
                  { campaignType ==6 && <Route  path="/campaign" element={<CampaignJourney4/>} />}
                  { campaignType ==7 && <Route  path="/campaign" element={<CampaignJourney7/>} />}
                  { campaignType ==8 && <Route  path="/campaign" element={<CampaignJourney8/>} />}

                   <Route  path="/userRegistration" element={<UserRegistration/>} />
                   <Route  path="/completeUserRegistration/:token/:userId" element={<CompleteUserRegistration/>} />
                   
                  <Route path="/campaignTheme/"  element={<CampaignJourneyThemes/>} >
                      <Route path=":course" element={<CampaignJourneyThemes/>}>
                          <Route path=":status" element={<CampaignJourneyThemes/>}/>
                      </Route>  
                  </Route> 
                  <Route path="/campaignThemeMobile/"  element={<CampaignJourneyThemesMobile/>} >
                      <Route path=":course" element={<CampaignJourneyThemesMobile/>}>
                          <Route path=":status" element={<CampaignJourneyThemesMobile/>}/>
                      </Route>  
                  </Route>                    
                  <Route  path="/campaignCompleteReg/:token/:userId" element={<CampaignCompleteRegistration />} />
                  <Route  path="/devices" element={<Devices/>} />
                  <Route  path="/courses" element={<Courses/>} />
                  <Route  path="/course" element={<Courses/>} />
                  <Route  path="/changePaymentMethod" element={<ChangePaymentMethod/>} />
                  <Route  path="/signUpSuccess" element={<Courses/>} />
                  <Route  path="/signUpSuccessDM" element={<Courses/>} />
                  <Route  path="/successDashboardDmOld" element={<Dashboard/>} />
                  <Route  path="/successDashboardDm" element={<DashboardCourses/>} />
                  <Route  path="/SuccessPricesDm" element={<PricesWizard/>} />
                  <Route path="/MENA" element={<MenaRoute/>}/>
                  <Route path="/testimonials" element={<Testimonials/>}/>
                  <Route  path="/paypalSimulation" element={<PaypalSimulation/>} />
                  <Route  path="/course/project/:id" element={<CourseProjectPage/>} />

                  {/* <Route  path="/videoPage" element={<VideoPage/>} /> */}
                  <Route  path="/testConversion" element={<TestConversion/>} />
                  <Route  path="/appleCallback" element={<AppleCallback/>} />
                  <Route  path="/localStreaming" element={<LocalStreaming/>} />


                </Routes>
                <Footer/>
                <LoginModal/> 
                <SurveyModal/> 
                <ForgetPasswordModal/> 
              </Profile>
              
               }

            </Langauge> 
          </Auth>
          </Suspense> 
        </Router> 
        </>
      }
        </>
        
    )
  }


export default App;
