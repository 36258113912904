
const serverDomain='https://admin.mawaheb.online/';//'http://40.115.115.198/mwaheb/';
const activeDomain = window.location.origin+'/'

const config={

'imgDns':'https://admin.mawaheb.online/',//'http://40.115.115.198/mwaheb/',
'domain':activeDomain||'https://staging.mawaheb.online/',//'http://13.79.17.237:3000/',//'http://localhost:3000/',
'serverDomain':serverDomain,
'menDomains': ['https://stagingRemove.mawaheb.global'],
'vasDomains': ['https://mob.mawaheb.online'],


    'videoLp':serverDomain+"Home/GetVideosLP?",
    'introApi':serverDomain+"Home/GetIntro?",
    'plansApi':serverDomain+"api/getPlans?",
    'coursesApi':serverDomain+"home/getCourses?",
    'loginApi': serverDomain+"api/login",



'applyNewPriceCardFeatures' : window.location.origin.includes('//staging.') || window.location.origin.includes('//localhost') || true,

'defualtLang':'en',
'plansApi':serverDomain+"api/getPlans?",
'planTypes':[
    {'id': 1,'key': 'parent_childs_plan','name':'Parent-Childs Plan'},
    {'id': 2,'key': 'adult_plan','name':'Adult Plan'},
    {'id': 3,'key': 'both_plan','name':'Both Plan'},
],
'subTypes':[
    {'id': 1,'key': 'yearly','name':'Yearly'},
    {'id': 2,'key': 'monthly','name':'Monthly'},
    {'id': 3,'key': 'daily','name':'Daily'},
    {'id': 4,'key': 'one_time','name':'One Time'},       
    {'id': 5,'key': 'quarterly','name':'Quarterly'},          
] 
,
'courseDetailsApi':serverDomain+"/CourseInfoApi",

'getGeneralData':serverDomain+"Home/getGeneralData?type=",
'chartColors' : ["rgba(239,62,91,1)","rgba(75,37,109,1)","rgba(63,100,126,1)","rgba(212,158,214,1)","rgba(149,212,122,1,1)","rgba(0,176,178,1)","rgba(103,124,138,1)","rgba(178,162,150,1)",,"rgba(201,201,201,1)","rgba(246,143,160,1)"],
'categoriesApi':serverDomain+"Home/getCategoriesLP?",
'executeContactUs':serverDomain+"Home/contact_us?",
'promoCodeDetailsApi':serverDomain+"Home/getPromoDetails?",
'months' : [
    {id:1, name:'jan'},
    {id:2, name:'feb'},
    {id:3, name:'mar'},
    {id:4, name:'apr'},
    {id:5, name:'may'},
    {id:6, name:'jun'},
    {id:7, name:'jul'},
    {id:8, name:'aug'},
    {id:9, name:'sep'},
    {id:10, name:'oct'},
    {id:11, name:'nov'},
    {id:12, name:'dec'},
],
"countries" : [
    {id:1, code:'jo', prefix:{ id:"+962", name:"+962" } },
    {id:5, code:'sy', prefix:{ id:"+963", name:"+963" } }, 
    {id:4, code:'iq', prefix:{ id:"+964", name:"+964" } },
    {id:3, code:'ae', prefix:{ id:"+971", name:"+971" } },         
    {id:2, code:'ps', prefix:{ id:"+972", name:"+972" } },      
    {id:6, code:'sa', prefix:{ id:"+966", name:"+966" } }, 
    {id:7, code:'kw', prefix:{ id:"+965", name:"+965" } }, 
    {id:8, code:'bh', prefix:{ id:"+973", name:"+973" } }, 

],
"notification_types" : [
    {id:1, keyname:"notify_child_login", account_type:[2]},
    {id:2, keyname:"notify_child_finish_course", account_type:[2]},
    {id:3, keyname:"notify_child_submit_project", account_type:[2]},
    {id:4, keyname:"notify_new_course_uploaded", account_type:[1,2,3]}
],
"birth_year_from" : 1950,
'menDomains': ['https://stagingRemove.mawaheb.global'],
'vasDomains': ['https://mob.mawaheb.online'],
'useBitmovinPlayer':true
}


export default config
